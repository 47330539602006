<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      elevate-on-scroll
    >
      <v-img
          alt="Vardi"
          class="shrink text-center"
          contain
          src="@/assets/logo1.png"
          width="280"
      />

    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer
        padless
        inset
        light
        class="mt-0 lighten-3">
      <v-col md="12" class="today-medium text-center primary--text text-decoration-none pa-1 gray text-caption" >
        Copyright © {{ new Date().getFullYear() }} VARDI INVERSIONES. Desarrollado por <a href="https://bio.credit/" class="text-decoration-none" target="_blank">BioCredit</a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>

.v-dialog {
  border-radius: 10px !important;
}

.v-text-field--outlined {
  border-radius: 6px !important;
}

.v-text-field--outlined fieldset {
  border-color: #00205B !important;
}

.theme--light.v-label {
  color: #00205B !important;
}

.v-toolbar__content {
  justify-content: center !important;
}

.wrap-text {
  white-space: normal;
  line-height: 110% !important;
}

.v-btn {
  letter-spacing: 0 !important;
}

.fill-height {
  margin-top: 0 !important;
}

</style>