import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    defaultTheme: 'light',
    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: '#00205B',
        secondary: '#FFD100',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#00205B',
        secondary: '#FFD100',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    },

  },
  lang: {
    locales: {es},
    current: 'es',
  },
});
