<template>
  <v-col class="d-none d-lg-flex bg-image" cols="12" md="5"
         :style="'background-image: url(' + image + ')'"
         >
  </v-col>
</template>

<script>
import {locals} from "@/helpers/locals";

export default{
  name: 'left-side-image',
  data() {
    return {
      settings: null,
      image: null,
      imageLength: 0,
      idImage: 0
    }
  },
  created() {
    this.interval = setInterval(() => {
      try {
        this.settings = locals.getLocal('settings');
        this.idImage = locals.getLocal('idImage') != null ? locals.getLocal('idImage') : 0;
      } catch (e) {
        console.log(e);
      }

      if(this.settings != null) {
        clearInterval(this.interval);

        this.settings.config.forEach((value) => {
          if(value.key === 'left_image') {
            this.imageLength = value.value.length;
            if(this.idImage === this.imageLength) {
              this.idImage = 0;
            }
            this.image = value.value[this.idImage].value;
            this.idImage += 1;
            locals.setLocal('idImage', this.idImage); // Se crea variable local para intercambiar las imagenes
          }
        });
      }

    }, 0);
  }
}
</script>

<style>
.bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
</style>